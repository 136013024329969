import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../assets/images/logo.png";

// const pages = ["Home", "About", "Farmers", "Partners", "Sign Up"];
const useStyles = makeStyles({
  root: {
    justifyContent: "flex-end",
    "&:hover": {
      background: "#126643",
    },
  },
});

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "white",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://www.projectgrow.ng/"
            sx={{
              mr: 2,
              //   display: { xs: 'none', md: 'flex' },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={logo}
              alt="Project Grow"
              className="hero-image rounded-2xl"
              href="https://www.projectgrow.ng/"
              style={{
                paddingLeft: "16px",
                width: "75%",
                height: "30%",
              }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "float", md: "none" } }}>
            <div
              style={{
                float: "right",
                left: "auto",
                right: 0,
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <GiHamburgerMenu fontSize={40} color="#239E6C" />
              </IconButton>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                href="/"
                style={{
                  color: "#00A15F",
                }}
              >
                Home
              </MenuItem>

              <MenuItem
                component={Link}
                href="/#about-us"
                onClick={handleCloseNavMenu}
              >
                About
              </MenuItem>
              <MenuItem
                component={Link}
                href="/farmers"
                onClick={handleCloseNavMenu}
              >
                Farmers
              </MenuItem>
              <MenuItem
                component={Link}
                href="/partners"
                onClick={handleCloseNavMenu}
              >
                Partners
              </MenuItem>
              <MenuItem
                component={Link}
                href="/blog"
                onClick={handleCloseNavMenu}
              >
                Blog
              </MenuItem>
              <MenuItem
                className={classes.root}
                onClick={handleMenu}
                sx={{
                  backgroundColor: "white",
                  color: "#00A15F",

                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                Products
              </MenuItem>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem
                  component={Link}
                  target="_blank"
                  href="https://credit.projectgrow.ng/"
                  className="MenuItem"
                  onClick={handleClose}
                  style={{
                    fontSize: "8px",
                    justifyContent: "center",
                    // color: "#00A15F",
                  }}
                  
                >
                  Credit
                </MenuItem>
                <MenuItem
                  component={Link}
                  target="_blank"
                  href="https://app.projectgrow.ng/"
                  className="MenuItem"
                  onClick={handleClose}
                  style={{
                    fontSize: "8px",
                    padding: 1,
                    // color: "#00A15F",
                  }}
                >
                  Project Monitoring System
                </MenuItem>
              </Menu>
            </Menu>
          </Box>

          {/* Desktop View */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              marginLeft: "40%",
            }}
          >
            <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "#00A15F",
                display: "block",
                textTransform: "none",
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} component={Link} href="/">
                Home
              </MenuItem>
            </Button>
            <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "black",
                display: "block",
                textTransform: "none",
              }}
            >
              <MenuItem
                component={Link}
                href="/#about-us"
                onClick={handleCloseNavMenu}
              >
                About
              </MenuItem>
            </Button>
            <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "black",
                display: "block",
                textTransform: "none",
              }}
            >
              <MenuItem
                component={Link}
                href="/farmers"
                onClick={handleCloseNavMenu}
              >
                Farmers
              </MenuItem>
            </Button>
            <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "black",
                display: "block",
                textTransform: "none",
              }}
            >
              <MenuItem
                component={Link}
                href="/partners"
                onClick={handleCloseNavMenu}
              >
                Partners
              </MenuItem>
            </Button>
            <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "black",
                display: "block",
                textTransform: "none",
              }}
            >
              <MenuItem
                component={Link}
                href="/blog"
                onClick={handleCloseNavMenu}
              >
                Blog
              </MenuItem>
            </Button>
            {/* <Button
              className={classes.root}
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                background: "#126643",
                borderRadius: "14px",
                padding: 0,
                textTransform: "none",
                "& .MenuItem.Mui-selected": {
                  "&:hover": '#126643"',
                },
              }}
            >
              <MenuItem
                selected
                className="MenuItem"
                component={Link}
                href="https://www.projectgrow.ng/"
                onClick={handleCloseNavMenu}
              >
                Products
              </MenuItem>
            </Button> */}

            <Button
              className={classes.root}
              onClick={handleMenu}
              sx={{
                my: 2,
                backgroundColor: "white",
                color: "#00A15F",
                display: "block",
                textTransform: "none",
                fontSize: "16px",
              }}
            >
              {/* <Button
              className={classes.root}
              onClick={handleMenu}
              aria-label="products"
              aria-controls="menu-appbar"
              aria-haspopup="true"
             
              sx={{
                my: 2,
                display: "block",
                color: "white",
                background: "#126643",
                borderRadius: "14px",
                textTransform: "none",
                padding: "8px, 16px, 8px, 16px",
                fontSize: "16px",
                "& .MenuItem.Mui-selected": {
                  "&:hover": "#126643",
                },
              }}
            > */}
              Products
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                marginTop: 6,
                padding: "10px",
                display: { xs: "none", md: "flex" },
              }}
            >
              <MenuItem
                component={Link}
                target="_blank"
                href="https://credit.projectgrow.ng/"
                className="MenuItem"
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                }}
              >
                Credit
              </MenuItem>
              <MenuItem
                component={Link}
                target="_blank"
                href="https://app.projectgrow.ng/"
                className="MenuItem"
                onClick={handleClose}
              >
                Project Monitoring System
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
